/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Button } from "@nordcloud/gnui";
import { BreadcrumbsBox, UniversalWrap, UnstyledLink } from "~/components";
import { ROUTES } from "~/routing/routes";
import { ACTIONS, Can } from "~/services/auth";
import { CostAllocationV2 } from "./components/CostAllocationV2";

export function ApplicationsPageV2() {
  const isLoading = false;
  const error = undefined;
  return (
    <UniversalWrap loaderProps={{ loading: isLoading }} errorProps={{ error }}>
      <BreadcrumbsBox title="Applications">
        <Can action={ACTIONS.addApplication}>
          <UnstyledLink to={ROUTES.applications.create}>
            <Button icon="plus">Add new Application</Button>
          </UnstyledLink>
        </Can>
      </BreadcrumbsBox>
      <CostAllocationV2 />
    </UniversalWrap>
  );
}
