/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useToggle } from "react-use";
import {
  Box,
  MultipleSelect,
  SelectButton,
  Spacer,
  theme,
  BrickLoader,
  Text,
} from "@nordcloud/gnui";
import { UniversalWrap } from "~/components";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSuccess, showError } from "~/services/toast";
import { awsAutoFormDefaultValues } from "../constants";
import { AwsAutoForm } from "./AwsAutoForm";
import { AwsManualForm } from "./AwsManualForm";
import { useAddAwsBillingData } from "./hooks/useAddAwsBillingData";
import { AwsFormFields, AwsFormSchema, FormData } from "./validators";

export function AddBillingData({ onClose }: { onClose: () => void }) {
  const [isFormManual, toggleIsFormManual] = useToggle(false);

  const formMethods = useForm<FormData>({
    resolver: yupResolver(AwsFormSchema),
    mode: "onChange",
    defaultValues: {
      ...(isFormManual ? { ...awsAutoFormDefaultValues } : {}),
    },
  });

  const { reset, setValue } = formMethods;

  const { addAwsBillingData, error, loading } = useAddAwsBillingData();

  const onSubmit = async (data: FormData) => {
    try {
      await addAwsBillingData(data);
      showSuccess(SUCCESS_TEXT.billingDataAdded);
      onClose();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  const sharedFormProps = {
    closeForm: onClose,
    onSubmit,
    showRegionDisclaimer: true,
  };

  const Form = isFormManual ? AwsManualForm : AwsAutoForm;

  useEffect(() => {
    if (isFormManual) {
      reset({ region: "", reportName: "", reportPathPrefix: "" });
    } else {
      setValue(AwsFormFields.REGION, awsAutoFormDefaultValues.region);
      setValue(AwsFormFields.REPORT_NAME, awsAutoFormDefaultValues.reportName);
      setValue(
        AwsFormFields.REPORT_PATH_PREFIX,
        awsAutoFormDefaultValues.reportPathPrefix
      );
    }
  }, [isFormManual, setValue, reset]);

  return (
    <Box boxStyle="lightGrey">
      <Text weight="medium">Billing Data Settings</Text>
      <MultipleSelect>
        <SelectButton
          name="auto"
          value="auto"
          labelText="Automatic (Recommended)"
          isActive={!isFormManual}
          onClick={toggleIsFormManual}
        />
        <SelectButton
          style={{ paddingInline: "6rem" }}
          name="manual"
          value="manual"
          labelText="Manual"
          isActive={isFormManual}
          onClick={toggleIsFormManual}
        />
      </MultipleSelect>
      <Spacer height={theme.spacing.spacing04} />
      <UniversalWrap
        loaderProps={{ loading, Component: BrickLoader }}
        errorProps={{ error }}
      >
        <FormProvider {...formMethods}>
          <Form {...sharedFormProps} />
        </FormProvider>
      </UniversalWrap>
    </Box>
  );
}
