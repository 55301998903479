/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, theme, Text } from "@nordcloud/gnui";
import { UniversalWrap } from "~/components";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSimpleInfo, showError } from "~/services/toast";
import { GcpForm } from "./GcpForm";
import { useUpdateGcpBillingData } from "./hooks/useUpdateGcpBillingData";
import { FormData, GcpFormFields } from "./types";

type Props = {
  organizationId: string;
  onboardedOrgId: string;
  projectId: string;
  onClose: () => void;
};

export function GcpBillingDataUpdateForm({
  organizationId,
  onboardedOrgId,
  projectId,
  onClose,
}: Props) {
  const [updateGcpBillingData, { loading, error }] = useUpdateGcpBillingData();

  const handleUpdate = async (data: FormData) => {
    try {
      await updateGcpBillingData({ ...mapUpdateInput(data), onboardedOrgId });
      showSimpleInfo(SUCCESS_TEXT.billingDataUpdated);
      onClose();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <UniversalWrap loaderProps={{ loading }} errorProps={{ error }}>
      <FlexContainer
        marginTop={theme.spacing.spacing04}
        direction="column"
        alignItems="flex-start"
        gap={theme.spacing.spacing01}
      >
        <Text weight="bold">Billing Data Settings</Text>
        <GcpForm
          disabledFields={[GcpFormFields.ORGANIZATION_ID]}
          defaultValues={{ organizationId, projectId }}
          onCloseForm={onClose}
          onSubmit={handleUpdate}
        />
      </FlexContainer>
    </UniversalWrap>
  );
}

function mapUpdateInput(data: FormData) {
  return {
    organizationId: data.organizationId,
    bigQueryDataset: data.bigQueryDatasetName,
    bigQueryTable: data.bigQueryTableName,
    serviceAccountKey: data.serviceAccountKey,
    projectId: data.projectId,
    useDetailedBillingData: data.useDetailedBillingData ?? false,
  };
}
