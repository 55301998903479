/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Then, Else, When, Switch, Case } from "react-if";
import { Link } from "react-router-dom";
import {
  Box,
  theme,
  FlexContainer,
  Text,
  Button,
  Modal,
  SVGIcon,
} from "@nordcloud/gnui";
import { useDisclosure, useToggle } from "~/hooks";
import { isNotNil } from "~/tools";
import { Provider } from "~/views/cloudAccounts/types";
import { AwsBillingDataUpdateForm } from "../aws/AwsBillingDataUpdateForm";
import { AzureBillingDataUpdateForm } from "../azure/AzureBillingDataUpdateForm";
import { GcpBillingDataUpdateForm } from "../gcp/GcpBillingDataUpdateForm";

type Props<T> = {
  provider: Provider;
  label: string;
  title: string;
  isActive?: boolean;
  linkTitle?: LinkTitle;
  interactionId: T;
  onDelete?: (interactionId: T) => void;
};

export function BillingDataListItem<T>({
  provider,
  label,
  title,
  isActive,
  interactionId,
  onDelete,
  linkTitle,
}: Props<T>) {
  const {
    close: closeDeleteModal,
    open: openDeleteModal,
    isOpen: isOpenDeleteModal,
  } = useDisclosure();

  const [showUpdateForm, toggleShowUpdateForm] = useToggle();

  const ActivityStatus = isNotNil(isActive) ? (
    <SVGIcon {...getAccountStatusStyle(isActive)} />
  ) : null;

  return (
    <>
      <Box border="disabled" boxStyle="lightGrey" mb={theme.spacing.spacing03}>
        <FlexContainer
          justifyContent="space-between"
          alignItems="center"
          gap={theme.spacing.spacing03}
        >
          <FlexContainer gap={theme.spacing.spacing03}>
            {ActivityStatus}
            <Title linkTitle={linkTitle} label={label} title={title} />
          </FlexContainer>
          <FlexContainer gap={theme.spacing.spacing03}>
            <Button
              severity="low"
              icon="edit"
              size="md"
              onClick={toggleShowUpdateForm}
            />
            <Button
              severity="low"
              icon="trash"
              size="md"
              onClick={openDeleteModal}
            />
          </FlexContainer>
        </FlexContainer>
        <When condition={showUpdateForm}>
          <UpdateForm
            gcpValues={{ organizationId: label, projectId: title }}
            provider={provider}
            title={title}
            interactionId={interactionId as string}
            onClose={toggleShowUpdateForm}
          />
        </When>
      </Box>
      <Modal
        isOpen={isOpenDeleteModal}
        contentLabel="Delete Billing Data"
        actions={[
          {
            order: 0,
            onAction: closeDeleteModal,
            label: "Cancel",
            severity: "low",
          },
          {
            order: 1,
            onAction: () => {
              onDelete?.(interactionId);
              closeDeleteModal();
            },
            label: "Delete",
            severity: "high",
          },
        ]}
        onClose={closeDeleteModal}
      >
        <Text>Are you sure you want to delete:</Text>
        <If condition={isNotNil(linkTitle)}>
          <Then>
            <Text>
              {linkTitle?.title} (<Text weight="bold">{title}</Text>)
            </Text>
          </Then>
          <Else>
            <Text weight="bold">{title}</Text>
          </Else>
        </If>
      </Modal>
    </>
  );
}

function Title({
  linkTitle,
  label,
  title,
}: {
  linkTitle?: LinkTitle;
  label: string;
  title: string;
}) {
  return (
    <FlexContainer direction="column" alignItems="flex-start">
      <If condition={isNotNil(linkTitle)}>
        <Then>
          <Link to={linkTitle?.link ?? ""}>{linkTitle?.title}</Link>
          <Text size="sm" color={theme.color.text.text02}>
            {title}
          </Text>
        </Then>
        <Else>
          <Text tag="span" size="sm" color={theme.color.text.text03}>
            {label}
          </Text>
          <Text>{title}</Text>
        </Else>
      </If>
    </FlexContainer>
  );
}

type UpdateFormProps = {
  provider: Provider;
  title: string;
  interactionId: string;
  gcpValues?: {
    organizationId: string;
    projectId: string;
  };
  onClose: () => void;
};

function UpdateForm({
  provider,
  title,
  interactionId,
  gcpValues,
  onClose,
}: UpdateFormProps) {
  return (
    <Switch>
      <Case condition={provider === Provider.Aws}>
        <AwsBillingDataUpdateForm close={onClose} accountId={title} />
      </Case>
      <Case condition={provider === Provider.Azure}>
        <AzureBillingDataUpdateForm
          capacitorCustomerId={interactionId}
          enrollmentNumber={title}
          onClose={onClose}
        />
      </Case>
      <Case condition={provider === Provider.Gcp}>
        <GcpBillingDataUpdateForm
          organizationId={gcpValues?.organizationId ?? ""}
          projectId={gcpValues?.projectId ?? ""}
          onboardedOrgId={interactionId}
          onClose={onClose}
        />
      </Case>
    </Switch>
  );
}

function getAccountStatusStyle(isActive: boolean) {
  if (isActive) {
    return {
      color: "success",
      name: "cloudOnline",
    } as const;
  }

  return {
    color: "danger",
    name: "cloudOffline",
  } as const;
}

type LinkTitle = {
  link: string;
  title: string;
};
