/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFormContext,
} from "react-hook-form";
import { When } from "react-if";
import {
  Message,
  Spacer,
  theme,
  Label,
  Input,
  Text,
  List,
  Dropdown,
  FlexContainer,
  Button,
} from "@nordcloud/gnui";
import { ExternalLink, FormGroup, FormHint } from "~/components";
import { REGIONS_AWS } from "~/constants/cloudProviderConst";
import { useCustomers } from "~/services/customers";
import {
  cloudFormationStackLink,
  cloudFormationStackLinkLite,
  SETUP_NOTES,
  costAndUsageReportLink,
} from "../constants";
import { FormData, AwsFormFields } from "./validators";

type Props = {
  closeForm: () => void;
  onSubmit: SubmitHandler<FormData>;
  showRegionDisclaimer?: boolean;
  disabledFields?: (keyof FormData)[];
};

export function AwsManualForm({
  closeForm,
  onSubmit,
  showRegionDisclaimer,
  disabledFields,
}: Props) {
  const formMethods = useFormContext();

  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="manual-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <When condition={showRegionDisclaimer}>
          <Message status="notification" image="info">
            Deploy CloudFormation stack in a selected region.
          </Message>
        </When>
        <Spacer height={theme.spacing.spacing04} />
        <FormHints />
        <Spacer height={theme.spacing.spacing02} />
        <AddBillingDataForm disabledFields={disabledFields} />
        <FlexContainer gap={theme.spacing.spacing03}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button severity="low" onClick={closeForm}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}

function AddBillingDataForm({ disabledFields = [] }: AddBillingDataHintsProps) {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <FormGroup error={errors[AwsFormFields.ACCOUNT_ID]}>
        <Label required htmlFor={AwsFormFields.ACCOUNT_ID} name="Account ID" />
        <Input
          disabled={disabledFields.includes(AwsFormFields.ACCOUNT_ID)}
          id={AwsFormFields.ACCOUNT_ID}
          {...register(AwsFormFields.ACCOUNT_ID)}
        />
      </FormGroup>
      <FormGroup error={errors[AwsFormFields.BUCKET_ID]}>
        <Label required htmlFor={AwsFormFields.BUCKET_ID} name="Bucket Name" />
        <Input
          id={AwsFormFields.BUCKET_ID}
          {...register(AwsFormFields.BUCKET_ID)}
        />
      </FormGroup>
      <FormGroup error={errors[AwsFormFields.REGION]}>
        <Label required name="Region" />
        <Controller
          control={control}
          name={AwsFormFields.REGION}
          rules={{ required: "This field is required." }}
          defaultValue={undefined}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              options={REGIONS_AWS}
              value={value}
              name="Select Region"
              minNumOfOptionsToShowSearchBox={99}
              onChange={onChange}
            />
          )}
        />
      </FormGroup>
      <FormGroup error={errors[AwsFormFields.REPORT_NAME]}>
        <Label
          required
          htmlFor={AwsFormFields.REPORT_NAME}
          name="Report Name"
        />
        <Input
          id={AwsFormFields.REPORT_NAME}
          {...register(AwsFormFields.REPORT_NAME)}
        />
      </FormGroup>
      <FormGroup error={errors[AwsFormFields.REPORT_PATH_PREFIX]}>
        <Label
          required
          htmlFor={AwsFormFields.REPORT_PATH_PREFIX}
          name="Report Path Prefix"
        />
        <Input
          id={AwsFormFields.REPORT_PATH_PREFIX}
          {...register(AwsFormFields.REPORT_PATH_PREFIX)}
        />
      </FormGroup>
    </>
  );
}

function FormHints() {
  const setupNotes = useMemo(
    () => SETUP_NOTES.map((note) => ({ title: note })),
    []
  );

  const { isAdminLite } = useCustomers();

  return (
    <>
      <FormHint stepNumber={1}>
        <Text>
          Download and deploy{" "}
          <ExternalLink
            href={
              isAdminLite
                ? cloudFormationStackLinkLite
                : cloudFormationStackLink
            }
          >
            CloudFormation stack
          </ExternalLink>{" "}
          in your organization&apos;s account.
        </Text>
      </FormHint>
      <FormHint stepNumber={2}>
        <Text>
          Go to{" "}
          <ExternalLink href={costAndUsageReportLink}>
            Cost and Usage Report (CUR)
          </ExternalLink>{" "}
          and follow the instructions:
        </Text>
      </FormHint>
      <FormHint stepNumber={3}>
        <div>
          <Text>You must do the following to configure your CUR:</Text>
          <List
            unordered
            ml={`-${theme.spacing.spacing02}`}
            p={0}
            items={setupNotes}
          />
        </div>
      </FormHint>
      <FormHint stepNumber={4}>
        <Text>
          Complete the information below to access your billing information.
        </Text>
      </FormHint>
    </>
  );
}

type AddBillingDataHintsProps = {
  disabledFields: Props["disabledFields"];
};
