/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useAwsBillingDataQuery } from "~/generated/graphql";

export function useAwsBillingData() {
  const { data, ...mutationState } = useAwsBillingDataQuery();

  const billingData =
    data?.awsBillingData.map((item) => ({
      displayName: item?.displayName ?? "",
      nid: item?.nid ?? "",
      accountId: item?.accountId ?? "",
      isActive: item?.isActive ?? false,
      isOnboarding: item?.isOnboarding ?? false,
    })) ?? [];

  return [billingData, mutationState] as const;
}
