/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useToggle } from "react-use";
import styled from "styled-components";
import {
  FlexContainer,
  theme,
  Text,
  MultipleSelect,
  SelectButton,
} from "@nordcloud/gnui";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { showSimpleInfo, showError } from "~/services/toast";
import { awsAutoFormDefaultValues } from "../constants";
import { AwsAutoForm } from "./AwsAutoForm";
import { AwsManualForm } from "./AwsManualForm";
import { useUpdateAwsBillingData } from "./hooks/useUpdateAwsBillingData";
import { AwsFormFields, AwsFormSchema, FormData } from "./validators";

export function AwsBillingDataUpdateForm({
  accountId,
  close,
}: {
  accountId: string;
  close: () => void;
}) {
  const [updateAwsBillingData] = useUpdateAwsBillingData();
  const [isFormManual, toggleIsFormManual] = useToggle(false);

  const formMethods = useForm<FormData>({
    resolver: yupResolver(AwsFormSchema),
    mode: "onChange",
    defaultValues: {
      accountId,
      ...(!isFormManual ? { ...awsAutoFormDefaultValues } : {}),
    },
  });

  const { reset, setValue } = formMethods;

  const AwsForm = isFormManual ? AwsManualForm : AwsAutoForm;

  const handleUpdate = async (data: FormData) => {
    try {
      await updateAwsBillingData(data);
      showSimpleInfo(SUCCESS_TEXT.billingDataUpdated);
      close();
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  useEffect(() => {
    if (isFormManual) {
      reset({ region: "", reportName: "", reportPathPrefix: "", accountId });
    } else {
      setValue(AwsFormFields.REGION, awsAutoFormDefaultValues.region);
      setValue(AwsFormFields.REPORT_NAME, awsAutoFormDefaultValues.reportName);
      setValue(
        AwsFormFields.REPORT_PATH_PREFIX,
        awsAutoFormDefaultValues.reportPathPrefix
      );
      setValue(AwsFormFields.ACCOUNT_ID, accountId);
    }
  }, [isFormManual, reset, setValue]);

  return (
    <FlexContainer
      marginTop={theme.spacing.spacing04}
      direction="column"
      alignItems="flex-start"
      gap={theme.spacing.spacing01}
    >
      <Text weight="bold">Billing Data Settings</Text>
      <MultipleSelect>
        <SelectButton
          name="auto"
          value="auto"
          labelText="Automatic (Recommended)"
          isActive={!isFormManual}
          onClick={toggleIsFormManual}
        />
        <SelectButton
          style={{ paddingInline: "6rem" }}
          name="manual"
          value="manual"
          labelText="Manual"
          isActive={isFormManual}
          onClick={toggleIsFormManual}
        />
      </MultipleSelect>
      <Seperator />
      <FormProvider {...formMethods}>
        <AwsForm
          closeForm={close}
          disabledFields={[AwsFormFields.ACCOUNT_ID]}
          onSubmit={handleUpdate}
        />
      </FormProvider>
    </FlexContainer>
  );
}

const Seperator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.color.border.input};
  margin-bottom: ${theme.spacing.spacing02};
  margin-top: ${theme.spacing.spacing02};
`;
