/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

enum SearchSortField {
  Cost = "COST",
  Forecast = "FORECAST",
  Nid = "NID",
  ProviderId = "PROVIDER_ID",
  Region = "REGION",
  SavingsAmount = "SAVINGS_AMOUNT",
  Type = "TYPE",
}

enum GroupSortField {
  TOTAL_COST = "COST",
  NAME = "NAME",
}

export const ESTATE_SORT_OPTIONS = [
  {
    value: SearchSortField.Cost,
    label: "Monthly Cost",
  },
  {
    value: SearchSortField.Forecast,
    label: "Monthly Forecast",
  },
  {
    value: SearchSortField.SavingsAmount,
    label: "Potential Savings",
  },
  {
    value: SearchSortField.Type,
    label: "Resource Types",
  },
  {
    value: SearchSortField.Region,
    label: "Region",
  },
];

export const APPLICATIONS_SORT_OPTIONS = [
  {
    value: GroupSortField.TOTAL_COST,
    label: "Total Cost",
  },
  {
    value: GroupSortField.NAME,
    label: "Application Name",
  },
];

export const PROVIDER_SORT_OPTIONS = [
  {
    value: GroupSortField.TOTAL_COST,
    label: "Total Cost",
  },
  {
    value: GroupSortField.NAME,
    label: "Provider",
  },
];

export const ENV_TYPE_SORT_OPTIONS = [
  {
    value: GroupSortField.TOTAL_COST,
    label: "Total Cost",
  },
  {
    value: GroupSortField.NAME,
    label: "Environment Type",
  },
];

export const REGION_SORT_OPTIONS = [
  {
    value: GroupSortField.TOTAL_COST,
    label: "Total Cost",
  },
  {
    value: GroupSortField.NAME,
    label: "Region",
  },
];

export const CATEGORY_SORT_OPTIONS = [
  {
    value: GroupSortField.TOTAL_COST,
    label: "Total Cost",
  },
  {
    value: GroupSortField.NAME,
    label: "Category Name",
  },
];

export const ACCOUNT_SORT_OPTIONS = [
  {
    value: GroupSortField.TOTAL_COST,
    label: "Total Cost",
  },
  {
    value: GroupSortField.NAME,
    label: "Account Name",
  },
];
