/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ComponentProps, ReactNode, useState } from "react";
import styled from "styled-components";
import {
  Box,
  FlexContainer,
  Spacer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";

type Props = {
  children: ReactNode;
  title: string;
  opened?: boolean;
  maxHeight?: string;
  boxProps?: ComponentProps<typeof Box>;
};

export function CollapsibleBox({
  children,
  title,
  opened = true,
  maxHeight = "2000px",
  boxProps,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(opened);

  return (
    <Box {...boxProps}>
      <FlexContainer justifyContent="space-between" alignItems="start">
        <Text weight="medium" mb={0}>
          {title}
        </Text>
        <ClickableSVGIcon
          name={isExpanded ? "chevronUp" : "chevronDown"}
          onClick={() => setIsExpanded((prev) => !prev)}
        />
      </FlexContainer>
      <Wrapper expanded={isExpanded} maxHeight={maxHeight}>
        <Spacer height={theme.spacing.spacing04} />
        {children}
      </Wrapper>
    </Box>
  );
}

const ClickableSVGIcon = styled(SVGIcon)`
  cursor: pointer;
`;

const Wrapper = styled.div<{ expanded: boolean; maxHeight: string }>`
  overflow: hidden;
  max-height: ${({ expanded, maxHeight }) => (expanded ? maxHeight : "0")};
  opacity: ${({ expanded }) => (expanded ? "1" : "0")};
  transition: ${({ expanded }) =>
    expanded
      ? "max-height 0.4s ease-in-out, opacity 0.2s linear 0.4s"
      : "max-height 0.4s ease-in-out 0.1s, opacity 0.2s linear"};
`;
