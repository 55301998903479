/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Then, Else } from "react-if";
import { generatePath } from "react-router-dom";
import { useToggle } from "react-use";
import { Button } from "@nordcloud/gnui";
import { UniversalWrap } from "~/components";
import { SUCCESS_TEXT, ERROR_TEXT } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { showError, showSimpleInfo } from "~/services/toast";
import { Provider } from "~/views/cloudAccounts/types";
import { BillingDataList } from "../components/BillingDataList";
import { BillingDataListItem } from "../components/BillingDataListItem";
import { AddBillingData } from "./AddBillingDataForm";
import { useAwsBillingData } from "./hooks/useAwsBillingData";
import { useDeleteAwsBillingData } from "./hooks/useDeleteAwsBillingData";

export function AwsBillingDataContent() {
  const [isAddBillingDataFormOpen, toggleIsAddBillingDataForm] =
    useToggle(false);

  const [billingData, { error, loading }] = useAwsBillingData();

  const [removeAwsBillingData] = useDeleteAwsBillingData();

  const handleDelete = async (interactionId: string) => {
    try {
      await removeAwsBillingData({ accountId: interactionId });
      showSimpleInfo(SUCCESS_TEXT.billingDataRemoved);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <UniversalWrap loaderProps={{ loading }} errorProps={{ error }}>
      <BillingDataList title="List of Cloud Accounts where CUR files are located">
        {billingData.map(({ displayName, accountId, nid, isActive }) => (
          <BillingDataListItem
            key={accountId}
            isActive={isActive}
            interactionId={accountId}
            label="Account ID"
            title={accountId}
            provider={Provider.Aws}
            linkTitle={getLinkTitle(nid, displayName)}
            onDelete={handleDelete}
          />
        ))}
      </BillingDataList>
      <If condition={isAddBillingDataFormOpen}>
        <Then>
          <AddBillingData onClose={toggleIsAddBillingDataForm} />
        </Then>
        <Else>
          <Button icon="plus" onClick={toggleIsAddBillingDataForm}>
            Add Billing Data
          </Button>
        </Else>
      </If>
    </UniversalWrap>
  );
}

function getLinkTitle(nid: string, displayName: string) {
  if (nid) {
    return {
      link: generatePath(ROUTES.cloudAccounts.details, { nid }),
      title: displayName,
    };
  }
}
